<template>
  <div class="container">
    <h2 class="text-center">Tag &lt;{{ tagName }}&gt;</h2>
    <div v-if="posts.length">
      <div class="grid-3_xs-1_sm-2_md-2" v-if="posts.length">
        <div
          :key="index"
          v-for="(post, index) in posts.slice(Math.max(posts.length - 6, 0)).reverse()"
          class="col"
        >
          <PostCard :that="that" :post="post" class="center" />
        </div>
      </div>
      <div class="center con-pagination">
        <vs-pagination v-model="currentPage" :length="Math.ceil(totalPostCount / 6)" not-margin progress />
      </div>
    </div>
  </div>
</template>

<script>
import PostCard from '@/components/PostCard.vue'

export default {
  name: 'ViewTag',
  components: {
    PostCard
  },
  data: function () {
    return {
      tagName: this.$route.params.tagName,
      currentPage: 1,
      pageSize: 6,
      totalPostCount: 0,

      posts: [],

      that: this
    }
  },
  watch: {
    currentPage (newValue, oldValue) {
      this.getPosts()
    }
  },
  methods: {
    getPosts () {
      this.request
        .GET('/tag/getPostsByTagname', {
          currentPage: this.currentPage,
          pageSize: this.pageSize,
          tagName: this.tagName
        })
        .then((res) => {
          if (res.data.length) {
            this.posts = res.data
            this.totalPostCount = res.data[0].total_post_count
          }
        })
    }
  },
  created () {
    this.getPosts()
  }
}
</script>
